exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-blogs-tsx": () => import("./../../../src/pages/blogs/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-blogs-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blogs/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blogs-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-dashboard-dashboard-tsx": () => import("./../../../src/pages/dashboard/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-dashboard-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-home-footprints-tsx": () => import("./../../../src/pages/home/footprints.tsx" /* webpackChunkName: "component---src-pages-home-footprints-tsx" */),
  "component---src-pages-home-home-tsx": () => import("./../../../src/pages/home/home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-performance-index-tsx": () => import("./../../../src/pages/performance/index.tsx" /* webpackChunkName: "component---src-pages-performance-index-tsx" */),
  "component---src-pages-performance-performance-tsx": () => import("./../../../src/pages/performance/performance.tsx" /* webpackChunkName: "component---src-pages-performance-performance-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-projects-tsx": () => import("./../../../src/pages/projects/projects.tsx" /* webpackChunkName: "component---src-pages-projects-projects-tsx" */),
  "component---src-pages-resume-index-tsx": () => import("./../../../src/pages/resume/index.tsx" /* webpackChunkName: "component---src-pages-resume-index-tsx" */),
  "component---src-pages-resume-resume-tsx": () => import("./../../../src/pages/resume/resume.tsx" /* webpackChunkName: "component---src-pages-resume-resume-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"us5.datadoghq.com","sampleRate":100,"enabled":true,"rum":{"applicationId":"4e2ee008-f188-492c-b4c6-6b695772d8de","clientToken":"pub433009872f641faefb3c0b2e009452d7"},"logs":{"clientToken":"pub433009872f641faefb3c0b2e009452d7"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"miever.net","short_name":"miever","start_url":"/","background_color":"#ffffff","theme_color":"#0CC0DF","display":"standalone","icons":[{"src":"/favicon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
